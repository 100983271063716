.search-bar {
    width: 100%;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    border-radius: 4px;
}

.input-search {
    width: 100%;
    padding: 5px;
    margin: 16px;
    font-family: 'Lato', sans-serif;
    font-size: 18px;
}

