.itemContainerTotal {
    display: flex;
    -webkit-box-align: stretch;
    align-items: stretch;
    -webkit-box-flex: 0;
    flex-grow: 0;
    margin: 0;
}

.itemContainer {
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: row;
    padding: 12px;
    margin: 8px 16px;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(181 129 108 / 80%) 0 8px 34px -25px;
    text-align: left;
}

.itemSectionContent {
    display: flex;
    flex-direction: column;
    -webkit-box-pack: justify;
    justify-content: space-between;
    width: 100%;
}

.itemSectionImage {
    display: flex;
    position: relative;
}

.itemAdvertisement {
    display: flex;
    margin-bottom: 4px;
}

.AdvertisementChip {
    margin-right: 5px;
    font-family: 'Lato', sans-serif;
    font-size: 11px;
    font-weight: 700;
    border-radius: 4px 10px 10px 4px
}

.itemTitle{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    flex-direction: row;
    margin-bottom: 4px;
}

.itemTitleText {
    color: rgb(16, 4, 35);
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    max-width: 100%;
}

.itemDescription {
    overflow: clip;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    margin-top: 4px;
    line-height: 16px;
    color: rgb(88, 80, 101);
    font-family: "Lato", sans-serif;
    font-size: 14px;
    font-weight: 400;
    height: 16px;
    max-height: 16px;
    transition: height 0.5s ease 0s;
}

.itemSectionPrice. {
    color: rgb(16, 4, 35);
    font-family: 'Lato', sans-serif;
    font-size: 16px;
    font-weight: 700;
    margin-top: 8px;
}

.itemPriceContainer {
    display: inline-block;
    padding-top: 8px;
    line-height: 1.25;
}

.itemPrice {
    font-family: 'Lato', sans-serif;
    font-size: 18px;
    font-weight: 700;
    padding-top: 6px;
    display: block;
    color: rgb(16, 4, 35);
}

.itemPriceText {
    margin-right: 4px;
}

.itemPriceDiscountText {
    text-decoration: line-through;
    font-family: 'Lato', sans-serif;
    font-size: 14px;
    font-weight: 400;
}

.price-container {
    margin-right: 10px;
    width: 30%;
    display: flex;
    align-items: center;
    justify-content: end;
}