@font-face {
    font-family: 'Montserrat';
    src: url('../fonts/Montserrat/Montserrat-Regular.ttf') format('truetype'),
         url('../fonts/Montserrat/Montserrat-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'Lato';
    src: url('../fonts/Lato/Lato-Regular.ttf') format('truetype'),
    url('../fonts/Lato/Lato-Bold.ttf') format('truetype');
}
