.businessList {
    width: 100%;
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
}

.categoryList {
    padding: 0;
    display: flex;
    flex-direction: column;
    list-style: none;
}

.containerHome {
    text-align: -webkit-center;
}

.itemCategory__title {
    font-family: 'Lato', sans-serif;
    text-align: left;
    margin: 15px 0 10px 15px;
    font-size: 20px;
    min-height: 20px;
    display: block;
}
