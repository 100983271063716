.containerBusiness {
    padding: 0;
    flex-direction: column;
    display: flex;
    margin: 12px 16px;
    border-radius: 6px;
    background: rgb(255, 255, 255);
    box-shadow: rgb(181 129 108 / 80%) 0 8px 34px -25px;
}

/*Anuncio que aparece sobre la card*/
.advertisementsBusiness {
    font-family: 'Lato', sans-serif;
    font-size: 12px;
    font-weight: 700;
    text-align: center;
    color: white;
    background: #4eac5b;
    padding: 4px;
    border-radius: 6px 6px 0 0;
    text-transform: uppercase;
}

.businessCardStructure {
    display: flex;
    flex-direction: row;
    padding: 12px;
    margin: 0;
}

/*Imagen del local*/
.imageBusinessContainer {
    padding-right: 12px;
    padding-top: 5px;
    align-self: center;
    display: flex;
    flex-direction: column;
}

.imageBusiness {
    display: flex;
    -webkit-box-pack: center;
    justify-content: center;
    width: 50px;
    height: 50px;
}

.imageValue {
    width: 50px;
    height: 50px;
    border-radius: 6px;
    border: 1px solid rgb(88, 80, 101);
    margin-left: 0;
}

.contentBusinessContainer {
    flex: 1 1 0;
    overflow: hidden;
}

/*Nombre del local*/
.contentBusinessTitle {
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 20px;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.contentBusinessTitleText {
    font-family: 'Lato', sans-serif;
    font-weight: 700;
    max-width: 85%;
    font-size: 18px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(16, 4, 35);
}

/*Metodos de pago*/
.contentBusinessMethodOfPayment {
    display: flex;
    flex-direction: column;
    margin-bottom: 4px;
}

.contentBusinessLocation{
    display: flex;
    -webkit-box-align: center;
    align-items: center;
    height: 20px;
    -webkit-box-flex: 1;
    flex-grow: 1;
}

.contentBusinessLocationText{
    font-family: 'Lato', sans-serif;
    max-width: 85%;
    font-size: 14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: rgb(16, 4, 35);
}

.contentBusinessMethodOfPayment01 {
    font-family: 'Lato', sans-serif;
    margin-left: 5px;
    display: flex;
    align-content: center;
    font-size: 14px;
    color: rgb(88, 80, 101);
}

/*Calificacion*/
.ratingsBusinessContainer {
    font-family: 'Lato', sans-serif;
    padding-top: 13px;
    position: relative;
}

.ratingsBusinessContent {
    width: fit-content;
    display: flex;
    flex-direction: row;
    -webkit-box-align: center;
    align-items: center;
    font-family: Lato, sans-serif;
    font-weight: 500;
    font-size: 14px;
    font-style: normal;
    letter-spacing: 0;
    line-height: 16px;
    text-decoration: inherit;
    text-transform: inherit;
    gap: 0 2px;
    color: rgb(16, 4, 35);
}