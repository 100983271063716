.header {
    display: flex;
    align-items: center;
    position: relative;
}

.header__cover-image {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 0 0 12px 12px;
}

.header__content {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 10px 10px;
}

.header__logo {
    width: 50px;
    height: 50px;
    object-fit: contain;
    margin-right: 20px;
    border-radius: 12px;
}

.header__title {
    font-size: 24px;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 800;
    line-height: 1.3;
    color: rgb(16, 4, 35);
    letter-spacing: normal;
}
