.header_mercado {
    display: flex;
    position: relative;
}

.header__cover-image_mercado {
    width: 100%;
    height: 250px;
    object-fit: cover;
    border-radius: 0 0 12px 12px;
}

.header__logo_mercado {
    width: 60px;
    height: 60px;
    object-fit: contain;
    margin-right: 20px;
    border-radius: 12px;
}

.header__title_mercado_mercado {
    font-size: 18px;
    margin: 0;
    font-family: 'Lato', sans-serif;
    font-weight: 600;
    color: rgb(16, 4, 35);
    letter-spacing: normal;
}

.header__content_mercado {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(255, 255, 255, 1);
    display: flex;
    align-items: center;
    padding: 20px;
    border-radius: 10px 10px 10px 10px;
}

.header__content_central_mercado {
    display: flex;
    flex-direction: column;
    align-items: self-start;
}

.header__content_central_mercado h2, .header__content_central_mercado p {
    margin: 0;
}

.header__location_mercado {
    flex-grow: 1;
    flex-direction: row;
    text-align: right;
}

.header__location_icon_mercado{
    width: 30px;
    height: 30px;
    object-fit: contain;
    border-radius: 12px;
}

.header__horarios_mercado {
    font-size: 15px;
    font-family: 'Lato', sans-serif;
    text-align: -webkit-left;
}