.App {
  text-align: center;
}

body {
  margin: auto;
  padding: 0;
  max-width: 800px;
  background-color: ghostwhite;
  user-select: none;
}
