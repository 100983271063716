.errorContainer {
    width: 100%;
    height: 100vh;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
}

.errorImage {
    width: 70%;
}
