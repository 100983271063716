footer div {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.item_redes-sociales{
    width: 50px;
    height: 50px;
}

.linea-separadora {
    width: calc(100% - 60px);
    margin: 10px 10px;
    border: 0;
    border-top: 2px solid #ccc;
}

.container_texto {
    margin-bottom: 10px;
    flex-direction: column;
}

.container_texto p a{
    text-decoration: none;
}

.container_texto p{
    margin: auto;
    font-family: 'Montserrat', sans-serif;
}